import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getCustomFormByModuleId, getFormList, saveFormSurveyDetails } from '../../services/CustomSettingService'
import { getFormById } from '../../services/CustomSettingService'
import { createForm } from '../../services/CustomSettingService'
import { updateForm } from '../../services/CustomSettingService'
import { deleteForm } from '../../services/CustomSettingService'
import { getModulesList } from '../../services/CustomSettingService'
import { getSurveyList } from '../../services/CustomSettingService'
import { getHolidayList } from '../../services/CustomSettingService'
import { getAllSurveyList } from '../../services/CustomSettingService'
import { stat } from 'fs'

interface GetFormListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
}

interface FormState {
  list: any
  counter: any
  FormList: any
  FormData: any
  createFormData: any
  updateFormData: any
  modulesList: any
  holidayList: any
  surveyList: any
  surveyData: any
  allSurveyList: any
  customFields:any
}

const initialState: FormState = {
  FormList: null,
  FormData: null,
  createFormData: null,
  updateFormData: null,
  modulesList: null,
  holidayList: null,
  surveyList: null,
  surveyData: null,
  allSurveyList: null,
  list: [],
  counter: 1,
  customFields:null
}

export const createFormApi = createAsyncThunk(
  'create-workflow/api/post',

  async (payload: any, { rejectWithValue }) => {
    try {
      const result = await createForm(payload)
      return result
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const getFormListApi = createAsyncThunk(
  'FormList/api/get',
  async (
    { pageNumber, pageSize, filterkey }: GetFormListParams,
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const result = await getFormList(pageNumber, pageSize, filterkey)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const getFormByIdApi = createAsyncThunk(
  'FormById/api/get',
  async (id: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getFormById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const updateFormApi = createAsyncThunk(
  'updateForm/api/put',

  async ({ id, payload }: { id: any; payload: any }, { rejectWithValue }) => {
    try {
      const result = await updateForm(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const deleteFormApi = createAsyncThunk(
  'Form/api/delete',
  async (id: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await deleteForm(id)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const getModulesListApi = createAsyncThunk(
  'ModulesList/api/get',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getModulesList()
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)



export const getHolidayListApi = createAsyncThunk(
  'HolidayList/api/get',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getHolidayList()
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const getSurveyListApi = createAsyncThunk(
  'SurveyList/api/get',
  async (id: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getSurveyList(id)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)
export const getAllSurveyListApi = createAsyncThunk(
  'AllSurveyList/api/get',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getAllSurveyList()
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)


export const saveFormSurveyDetailsApi = createAsyncThunk(
  'SaveSurvey/api/post',

  async (payload: any, { rejectWithValue }) => {
    try {
      const result = await saveFormSurveyDetails(payload)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const GetCustomFormByModuleId = createAsyncThunk(
  'CustomFormbyModuleId',
  async (id:any,{rejectWithValue})=>{
    try {
      const result = await getCustomFormByModuleId(id);
      return result.data;
    }
    catch (error) {
      return rejectWithValue({error})
    }
  }
)



const CustomSettingSlice = createSlice({
  name: 'fields',
  initialState, // Initialize counter to 1
  reducers: {
    addField: (state: any, action: any) => {
      const newField = { ...action.payload, id: state.counter++ }
      state.list.push(newField)
    },
    addAllFields: (state: any, action: any) => {
      console.log(action.payload)
      state.list = action?.payload
    },
    updateField: (state: any, action) => {
      const { id, updatedField } = action.payload
      const updatedList = state.list.map((field: any, index: any) => {
        if (index == id) {
          return { ...updatedField }
        }
        else {
          return { ...field }
        }
      }
      )
      state.list = updatedList
    },
    deleteField: (state, action) => {
      const id = action.payload
      state.list = state.list.filter((field: any) => field.id !== id)
    },
    deleteAllField: (state) => {
      state.list = []
    },
    clearFormData: (state) => {
      state.FormData = null
    },
    clearSurveyList: (state) => {
      state.surveyList = []
    },
    clearAllSurveyList: (state) => {
      state.allSurveyList = []
    },
    addCutomField: (state,action) =>{
      console.log("field added ----  ",action.payload)
       state.customFields = action.payload;
    },
    removeCustomField: ( state)=>{
       state.customFields = null
    },
    addFieldValue:(state,action)=>{
       state.customFields[action.payload.index]["value"]=action.payload.value
       console.log("field hit and value added",action.payload.index,action.payload.value,state.customFields[action.payload.index].value)
      //  return state
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFormListApi.fulfilled, (state, action) => {
        state.FormList = action.payload
      })
      .addCase(getFormByIdApi.fulfilled, (state, action) => {
        state.FormData = action.payload
        state.list = action.payload.data.data.form_json
      })
      .addCase(createFormApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createFormData = action?.payload
      })
      .addCase(updateFormApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateFormData = action?.payload
      })
      .addCase(getModulesListApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.modulesList = action?.payload
      })
      .addCase(getHolidayListApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.holidayList = action?.payload
      })
      .addCase(getSurveyListApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.surveyList = action?.payload
      })
      .addCase(getAllSurveyListApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.allSurveyList = action?.payload
      })
      .addCase(saveFormSurveyDetailsApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.surveyData = action?.payload
      })
      .addCase(GetCustomFormByModuleId.fulfilled,(state,action)=> {
        state.customFields= action?.payload
      })
  },
})

export const { addField, addAllFields, clearAllSurveyList, clearSurveyList, updateField, clearFormData, deleteField, deleteAllField ,addCutomField,  removeCustomField,addFieldValue} = CustomSettingSlice.actions
export default CustomSettingSlice
