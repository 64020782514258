import instance from './instance'

export const createBankDetails = async (payload: any) => await instance.post('/modules-valtech/bank', payload)

export const getBankDetailsList = (page: number, pageSize: number, filterkey: any,isDropdown:any) =>
  instance.get(`/modules-valtech/bank?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)

export const updateBankDetails = async (id: any, payload: any) => await instance.post(`/modules-valtech/bank/${id}`, payload)

export const deleteBankDetails = (id: any) =>
  instance.delete(`/modules-valtech/bank/${id}`)

export const getBankDetailsById = (id: number) => instance.get(`/modules-valtech/bank/${id}`)
