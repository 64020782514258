import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import {

 getCaseById,getCaseList,deleteCase,createCase,updateCase,
 SaveAttachment,
 DeleteAttachment,

} from '../../services/CaseService'


interface GetCaseListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown: number
}

interface CaseState {
  caseList: any
  caseData: any
  createCaseData: any
  updateCaseData: any
}

const initialState: CaseState = {
  caseList: null,
  caseData: null,
  createCaseData: null,
  updateCaseData: null,
}

export const createCaseApi = createAsyncThunk(
  'Case/api/post',
  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createCase(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getCaseListApi = createAsyncThunk(
  'CaseList/api/get',
  async (
    {pageNumber, pageSize, filterkey, isDropdown}: GetCaseListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getCaseList(pageNumber, pageSize, filterkey,isDropdown)
      return result.data
    }
    catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getCaseByIdApi = createAsyncThunk(
  'CaseById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getCaseById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateCaseApi = createAsyncThunk(
  'updateCase/api/put',
  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateCase(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteCaseApi = createAsyncThunk(
  'Case/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteCase(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)


export const SaveCaseAttachmentApi = createAsyncThunk(
  'Case/api/caseattchment' ,
  async (data:any , {rejectWithValue, fulfillWithValue})=>{
    try {
      const result = await SaveAttachment(data)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const DeleteCaseAttachmentApi = createAsyncThunk(
  'Case/api/AttachmentDelete' ,
  async (data:any , {rejectWithValue, fulfillWithValue})=>{
    try {
      const result = await DeleteAttachment(data)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

// createCaseModule

const CaseSlice = createSlice({
  name: 'Case',
  initialState,
  reducers: {
    
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCaseListApi.fulfilled, (state, action) => {
        state.caseList = action.payload
      })
      .addCase(getCaseByIdApi.fulfilled, (state, action) => {
        state.caseData = action.payload
      })
      .addCase(createCaseApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createCaseData = action?.payload
      })
      .addCase(updateCaseApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateCaseData = action?.payload
      })
      
  },
})

export default CaseSlice
