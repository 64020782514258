import { FC, useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter, Navigate, HashRouter } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage } from '../modules/auth';
import { App } from '../App';



const AppRoutes: FC = () => {
  const [auth, setAuth] = useState(false);
  let token = localStorage.getItem('loginToken');
  
  useEffect(() => {
    if (token) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [token]);





  return (
    <HashRouter basename={''}>
      <Routes>
           <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {auth ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </HashRouter>
  );
};

export { AppRoutes };
