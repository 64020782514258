import instance, { API_URL } from './instance'
import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'

export const createForm = (payload: any) => instance.post('modules-customized-forms/form', payload)

export const getFormList = (page: number, pageSize: number, filterkey: any) =>
  instance.get(
    `modules-customized-forms/forms?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}`
  )

export const updateForm = (id: any, payload: any) =>
  instance.post(`modules-customized-forms/form/${id}`, payload)

export const deleteForm = (id: any) => instance.delete(`modules-customized-forms/form/${id}`)

export const getFormById = (id: any) => instance.get(`modules-customized-forms/forms/${id}`)

export const getModulesList = () => instance.get(`modules`)

export const uploadFile = (formData: any) => {
  const token = localStorage.getItem('loginToken')

  // Making a POST request with token and FormData
  axios.post(`${API_URL}/modules-customized-forms/uploadfile`, formData, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': `multipart/form-data`,
    }
  })
    .then(response => {
      console.log('Response data:', response.data);
      // Handle response data
    })
    .catch(error => {
      console.error('Error:', error);
      // Handle error
    });

  // const headers = new Headers()
  // headers.append('Authorization', `Bearer ${token}`)

  // // Making a POST request with FormData and token using fetch API
  // fetch(`${API_URL}/modules-customized-forms/uploadfile`, {
  //   method: 'POST',
  //   headers: headers,
  //   body: formData,
  // })
  //   .then((response) => response.json())
  //   .then((data) => {
  //     console.log('Data:', data)
  //     // Handle response data
  //   })
  //   .catch((error) => {
  //     console.error('Error:', error)
  //     // Handle error
  //   })
}

export const getHolidayList = () => instance.get(`modules-customized-forms/exportfile`)

export const getSurveyList = (id: number) =>
  instance.get(`modules-customized-forms/store-form-data/${id}`)

export const getAllSurveyList = () => instance.get(`modules-customized-forms/store-form-data`)

export const saveFormSurveyDetails = (payload:any) => instance.post(`modules-customized-forms/store-form-data`,payload)

export const getCustomFormByModuleId = (id:any) => instance.get(`modules-customized-forms/forms-by-module/${id}`)

