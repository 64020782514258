import instance from './instance'

export const createCase = async (payload: any) => await instance.post('/modules-valtech/case', payload)

export const getCaseList = (page: number, pageSize: number, filterkey: any,isDropdown:any) =>
  instance.get(`/modules-valtech/case?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)

export const updateCase = async (id: any, payload: any) => await instance.post(`/modules-valtech/case/${id}`, payload)

export const deleteCase = (id: any) =>
  instance.delete(`/modules-valtech/case/${id}`)

export const getCaseById = (id: number) => instance.get(`/modules-valtech/case/${id}`)

export const SaveAttachment = (data: any) => instance.post(`/modules-valtech/case-attachment`,data)

export const DeleteAttachment = (id: any) => instance.delete(`/modules-valtech/case-attachment/${id}`)


// https://valtech.developerbox.co.in/api