import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { addCutomField, GetCustomFormByModuleId } from '../../../../app/redux/slices/CustomSettingSlices';

const json = [
        {
            "id": 1,
            "type": "text",
            "label": "Address",
            "is_required": 1,
            "placeholder": "Address"
        }
    ]
 
export const UseCustom = () => {
    
    var [customFields,setCustomFields] = useState<any>(null);
    var dispatch = useDispatch<any>()


    const checkForCustomField = async  (moduleId:any)=>{
       await dispatch(GetCustomFormByModuleId(moduleId)).then((r:any)=>console.log(r))
    //    dispatch(addCutomField(json))
       window.localStorage.setItem("module_id",moduleId);
    //    setCustomFields([json]);
    }


    useEffect(()=>{
        checkForCustomField(window.localStorage.getItem("module_id"))
    },[])


    return {customFields , checkForCustomField}
}
